.logo-navbar {
  width: 5rem;
  border-radius: 10px;
}
.background-navbar {
  background: #000000;
  background: -moz-linear-gradient(
    -45deg,
    #000000 0%,
    #0b1530 41%,
    #101d42 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #000000 0%,
    #0b1530 41%,
    #101d42 100%
  );
  background: linear-gradient(135deg, #000000 0%, #0b1530 41%, #101d42 100%);
}

.nav-link {
  color: white !important;
  margin-right: 2rem;
  padding-bottom: 0.2rem !important;
}

.nav-link:hover {
  border-bottom: 1px white solid;
}

.zoom-in-nav {
  animation: zoomIn 0.3s ease-in-out;
}

@keyframes zoomIn {
  from {
    opacity: (0); /* Scala iniziale (0 significa invisibile) */
  }
  to {
    opacity: (1); /* Scala finale (1 significa dimensioni originali) */
  }
}

.toggle-white {
  border: 2px solid white !important;
}

.displayNone {
  display: none !important;
}
