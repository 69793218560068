.color-blunav {
  background-color: #0f1c41 !important;
}
.colortext-blunav {
  color: #0f1c41 !important;
}

.bold-600 {
  font-weight: 600 !important;
}
.line-height3 {
  line-height: 3rem;
}

.line-height2 {
  line-height: 2rem;
}
.color-oro {
  color: #ecc684 !important;
}

.container-style {
  background-color: white;
}

.w-70 {
  width: 70% !important;
}

.button-presentation {
  background-color: #d02629 !important;
  color: rgb(252, 252, 252) !important;
  font-weight: bold !important;
  border: transparent !important;
  -webkit-box-shadow: 1px 5px 16px 1px rgba(0, 0, 0, 0.59);
  box-shadow: 1px 5px 16px 1px rgba(0, 0, 0, 0.59);
}

.button-presentation:hover {
  background-color: #721517 !important;
}
@media (max-width: 767px) {
  .width-100Smarphone {
    width: 100% !important;
  }
}
@media (min-width: 767px) {
  .width-70Pc {
    width: 70% !important;
  }
}

.color-title-card {
  color: #c6bca9 !important;
}

.overflow-hidden {
  overflow: hidden !important;
}
