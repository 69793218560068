.logo-welcome {
  width: 30% !important;
}

.overflowHiddenIntro {
  overflow-x: hidden !important;
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

.zoom-in {
  animation: zoomIn 0.5s ease-in-out;
}

@keyframes zoomIn {
  from {
    transform: scale(1); /* Scala iniziale (0 significa invisibile) */
  }
  to {
    transform: scale(0); /* Scala finale (1 significa dimensioni originali) */
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button-presentation {
  background-color: #d02629 !important;
  color: rgb(252, 252, 252) !important;
  font-weight: bold !important;
  border: transparent !important;
  -webkit-box-shadow: 1px 5px 16px 1px rgba(0, 0, 0, 0.59);
  box-shadow: 1px 5px 16px 1px rgba(0, 0, 0, 0.59);
}

.button-presentation:hover {
  background-color: #721517 !important;
}

.color-title-hr {
  color: #ecc684 !important;
}

.img-zoom {
  animation: zoomOut 1.5s ease; /* Utilizza l'animazione zoomIn definita nei keyframes */
}

@keyframes zoomOut {
  from {
    transform: scale(1.2); /* Scala iniziale (nessun zoom) */
  }
  to {
    transform: scale(1); /* Scala finale (zoom del 20%) */
  }
}

.colortext-blunav {
  color: #0f1c41 !important;
}

@media only screen and (max-width: 767px) {
  .logo-welcome {
    display: none !important;
  }
}

/* Schermi desktop */
@media only screen and (min-width: 768px) {
  .logo-welcome {
    width: 30% !important;
  }
}
