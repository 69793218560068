.logo-style {
  width: 15rem;
  margin: auto;
}
.ul-style > li {
  margin-bottom: 0.5rem;
  list-style: none;
  text-align: center;
}

.footer-background {
  background: #000000;
  background: -moz-linear-gradient(
    -45deg,
    #000000 0%,
    #0b1530 41%,
    #101d42 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #000000 0%,
    #0b1530 41%,
    #101d42 100%
  );
  background: linear-gradient(135deg, #000000 0%, #0b1530 41%, #101d42 100%);

  color: white;
}

.style-socialIcon:hover {
  transform: scale(1.3);
  color: black !important;
}

.style-socialIcon {
  color: #7a7a7a !important;
}

.color-section {
  color: #7a7a7a;
}

.w-numberGreen {
  width: 12rem;
}

@media (max-width: 767px) {
  .marginSmarphone {
    padding-bottom: 5rem !important;
  }
}

.button-link {
  background-color: transparent !important;
  color: #0b5ed7 !important;
  border: transparent !important;
}

.button-link:hover {
  background-color: transparent !important;
  color: #ffffff !important;
  border: transparent !important;
}

.button-linkWhite {
  background-color: transparent !important;
  color: #ffffff !important;
  border: transparent !important;
}

.button-linkWhite:hover {
  background-color: transparent !important;
  color: #c92323 !important;
  border: transparent !important;
}

.w-AllCodingLogo {
  width: 3rem;
}
.colorAllCoding {
  color: #cba46b;
  text-decoration: none;
}
