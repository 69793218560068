.button-form {
  background-color: #d02629 !important;
  color: rgb(252, 252, 252) !important;
  font-weight: bold !important;
  border: transparent !important;
  -webkit-box-shadow: 1px 5px 16px 1px rgba(0, 0, 0, 0.59);
  box-shadow: 1px 5px 16px 1px rgba(0, 0, 0, 0.59);
}

.button-form:hover {
  background-color: #721517 !important;
}

.bgBluOpacity07 {
  background-color: rgb(15, 28, 65, 0.7) !important;
}

@media (max-width: 767px) {
  .wd100Smarphone {
    width: 100% !important;
  }
}
@media (min-width: 767px) {
  .width50Pc {
    width: 60% !important;
  }
}

.animated-gradientForm {
  width: 100%;

  background: linear-gradient(
    45deg,
    #140636,
    #050e42,
    #0b2e63,
    #064269
  ) !important;
  background-size: 150% 150% !important;
  animation: gradientAnimationForm 2s ease infinite !important;
}

@keyframes gradientAnimationForm {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.red-logoForm {
  color: #c92323 !important;
}

.input-style {
  margin-bottom: 2rem;
}

.border-box {
  box-sizing: border-box !important;
}
.radius-10 {
  border-radius: 10px !important;
}

.icon-medie {
  font-size: 3rem !important;
}
