@media only screen and (min-width: 767px) {
  .socialIconsBar {
    position: fixed !important;
    top: 60% !important;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    background-color: #084072;
  }
}

.icon {
  font-size: 1.5rem !important;
  color: #fff;
  margin: 0.5rem;
  text-decoration: none;
}

.icon:hover {
  color: #ce2424;
}

@media only screen and (max-width: 767px) {
  /* Stili da applicare solo su dispositivi con larghezza massima di 767px (tipicamente dispositivi mobili) */
  .socialIconsBarMobile {
    position: fixed;

    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    background: #084072;
    width: 100%;
    justify-content: flex-start;
    height: 5rem;
    padding-left: 2rem;
  }

  .icon {
    font-size: 1.5rem !important;
    color: #fff;
    margin-right: 3rem;

    text-decoration: none;
  }
}

.z-index-200 {
  z-index: 200;
}
