* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  /* overflow-x: hidden !important; */
  font-family: "Roboto", sans-serif;
  margin: 0 !important;
  padding: 0 !important;
  background-image: url("./asset/BG-definitivo3.png"); /* Sostituisci con il percorso reale della tua immagine */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Fissa l'immagine di sfondo */
  height: auto; /* Imposta l'altezza al 100% della viewport */
  width: 100%;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -20;
}
