.button-ScrollForm {
  background-color: #d02629 !important;
  color: rgb(252, 252, 252) !important;
  font-weight: bold !important;
  border: transparent !important;
  -webkit-box-shadow: 1px 5px 16px 1px rgba(0, 0, 0, 0.59);
  box-shadow: 1px 5px 16px 1px rgba(0, 0, 0, 0.59);
}

.button-ScrollForm:hover {
  background-color: #721517 !important;
}
